import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import MenuButton from '../../../../MenuButton/viewer/skinComps/SeparateLinesMenuButtonNSkin/SeparateLinesMenuButtonNSkin';
import styles from './SeparateLinesMenuButtonSkin.scss';
import SeparateLinesMenuButton from './SeparateLinesMenuButton';

const SeparateLinesMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return (
    <SeparateLinesMenuButton {...props} styles={styles} Button={MenuButton} />
  );
};

export default SeparateLinesMenuButtonSkin;
