import * as React from 'react';
import { IDropDownMenuBaseProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';

const SeparateLinesMenuButton: React.FC<IDropDownMenuBaseProps> = props => {
  const { styles, Button } = props;
  return <DropDownMenuBase {...props} styles={styles} Button={Button} />;
};

export default SeparateLinesMenuButton;
